<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5 bg-light"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            src="@/assets/images/logo/logo.png"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5 bg-white"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-0 font-weight-bolder"
            title-tag="h3"
          >
            Inicio de sesión
          </b-card-title>
          <b-card-text class="mb-1">
            Plataforma Cottés NEXT
          </b-card-text>
          <hr class="border-primary mb-1 mx-0 width-separator">
          <!-- form -->
          <validation-observer
            ref="loginForm"
            v-slot="{ invalid }"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="handleSubmit"
            >
              <!-- email -->
              <b-form-group
                label="Correo electrónico"
                label-for="login-email"
                class="text-secondary"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="email"
                    data-cy="login-email"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Contraseña"
                label-for="login-password"
                class="text-secondary"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      data-cy="login-password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="rememberMe"
                  name="checkbox-1"
                  class="text-secondary"
                >
                  Recordar contraseña
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                data-cy="login-submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Iniciar sesión
              </b-button>
            </b-form>
          </validation-observer>
          <b-row
            align-h="center"
            class="mt-1"
          >
            <b-col cols="auto">
              <b-link
                id="show-btn click"
                @click="handleRecoverPasswordLinkClick"
              >
                Recuperar contraseña
              </b-link>
              <RecoverPasswordModal v-model="recoverPasswordModalVisible" />
            </b-col>
          </b-row>
        </b-col>
      </b-col>
      <!-- /login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapGetters, mapActions } from 'vuex'
import { $themeConfig } from '@themeConfig'
import RecoverPasswordModal from '@/views/login/RecoverPasswordModal.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    RecoverPasswordModal,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      password: '',
      email: '',
      rememberMe: false,
      recoverPasswordModalVisible: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      rememberUser: 'getRememberUser',
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    this.populateLoginForm()
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
      loadRememberUser: 'auth/loadRememberUser',
    }),
    populateLoginForm() {
      this.loadRememberUser()
      if (!this.rememberUser) {
        return
      }

      this.email = this.rememberUser.email
      this.password = this.rememberUser.password
      this.rememberMe = this.rememberUser.rememberMe
    },
    handleSubmit() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          const { email, password, rememberMe } = this
          this.login({ email, password, rememberMe })
        }
      })
    },
    handleRecoverPasswordLinkClick() {
      this.recoverPasswordModalVisible = true
    },
  },
  setup() {
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.width-separator {
  width: 87px;
}
</style>
