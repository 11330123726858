<template>
  <b-modal
    id="forgot-password"
    :hide-footer="true"
    size="lg"
    v-model="model"
  >
    <validation-observer ref="forgotPassword">
      <b-card-title
        title-tag="h1"
        class="font-weight-bolder mb-1 text-center"
      >
        Restablecer contraseña
      </b-card-title>
      <div class="asignar-trabajos-modal">
        <b-row
          class="justify-content-center middle"
          align-v="center"
        >
          <b-col
            class="text-center"
            lg="10"
          >
            <span>Introduce el email que tienes asociado a tu cuenta y te mandaremos un email desde el que podrás cambiar tu contraseña.</span>
          </b-col>
        </b-row>

        <b-form
          class="mt-2"
          @submit.prevent="handleSubmitForgotPassword"
          @keydown.enter="handleSubmitForgotPassword"
        >
          <b-container class="pl-3 pr-3">
            <b-row
              class="justify-content-center middle"
              align-v="center"
            >
              <b-col lg="8">
                <validation-provider
                  #default="{ errors }"
                  :name="'Email'"
                  rules="required|email"
                >
                  <b-form-group
                    :label="'Email'"
                    label-for="forgot-email"
                  >
                    <b-form-input
                      id="login-recover-email"
                      v-model="userEmailForgot"
                      :state="errors.length > 0 ? false:null"
                      name="login-email-forgot"
                      placeholder="john@example.com"
                    />

                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="text-center mb-3">
              <b-col>
                <b-button
                  type="submit"
                  variant="primary"
                  class="mt-1 mr-1 text-uppercase"
                >
                  {{ 'Enviar' }}
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-form>
      </div>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  name: 'RecoverPasswordModal',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userEmailForgot: '',
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
  },
  methods: {
    ...mapActions('auth', {
      recoverPassword: 'recoverPassword',
    }),
    handleSubmitForgotPassword() {
      this.$refs.forgotPassword.validate().then(success => {
        if (success) {
          this.recoverPassword({ email: this.userEmailForgot })
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
